import React, { useState } from 'react';
import { Button, Container, Form, Alert, Spinner} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger'; 
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';


function AddCommentToOverdueTasks() {
  const [projectIdOrUrl, setProjectIdOrUrl] = useState('');
  const [comment, setComment] = useState('');
  const [includeSubtasks, setIncludeSubtasks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { log } = useFirebaseLogger();
  const asanaToken = localStorage.getItem('asanaToken');
  requireAuth(asanaToken);
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setMessage('');
    
        const projectId = projectIdOrUrl.match(/(\d+)$/)?.[0] || projectIdOrUrl;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        log(localStorage.getItem('asanaUserName'),"Used AddCommentToOverdueTasks Script"); 

        try {
          const tasksResponse = await axios.get(`https://app.asana.com/api/1.0/projects/${projectId}/tasks?opt_fields=due_on,name,gid`, {
            headers: { 'Authorization': `Bearer ${asanaToken}` },
          });
    
          const tasks = tasksResponse.data.data;
    
          for (const task of tasks) {
            const taskDueDate = task.due_on ? new Date(task.due_on) : null;
            if (taskDueDate) {
              taskDueDate.setHours(0, 0, 0, 0);
              if (taskDueDate < today) {
                await axios.post(`https://app.asana.com/api/1.0/tasks/${task.gid}/stories`, {
                  data: { text: comment },
                }, {
                  headers: { 'Authorization': `Bearer ${asanaToken}` },
                });
              }
            }
    
            if (includeSubtasks) {
              const subtasksResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${task.gid}/subtasks?opt_fields=due_on,name,gid`, {
                headers: { 'Authorization': `Bearer ${asanaToken}` },
              });
              const subtasks = subtasksResponse.data.data;
    
              for (const subtask of subtasks) {
                const subtaskDueDate = subtask.due_on ? new Date(subtask.due_on) : null;
                if (subtaskDueDate) {
                  subtaskDueDate.setHours(0, 0, 0, 0);
                  if (subtaskDueDate < today) {
                    await axios.post(`https://app.asana.com/api/1.0/tasks/${subtask.gid}/stories`, {
                      data: { text: comment },
                    }, {
                      headers: { 'Authorization': `Bearer ${asanaToken}` },
                    });
                  }
                }
              }
            }
          }
    
      setMessage('Комментарии успешно добавлены к просроченным задачам.');
    } catch (error) {
      console.error('Ошибка при добавлении комментариев:', error);
      log(localStorage.getItem('asanaUserName'),"ERROR when using AddCommentToOverdueTasks Script"); 
      setMessage(`Ошибка: ${error.response?.data?.errors?.[0]?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Добавление комментариев к просроченным задачам</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка или ID проекта</Form.Label>
          <TooltipButton tooltipText="Введите ссылку или ID проекта в Asana, в котором будет произведен поиск" style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={projectIdOrUrl} onChange={e => setProjectIdOrUrl(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Комментарий</Form.Label>
          <TooltipButton tooltipText="Введите комментарий, который оставит скрипт. Комментарий будет оставлен от вашего имени" style={{ marginLeft: '5px' }} />
          <Form.Control as="textarea" rows={3} value={comment} onChange={e => setComment(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
              type="checkbox"
              label={
                <>
                 Заходить в подзадачи?
                 <TooltipButton tooltipText="Если галочка установлена, то скрипт будет проходиться и подзадачам. Если задачи находятся и сабтаском и в проекте - скрипт оставит комментарий два раза." style={{ marginLeft: '5px' }} />
                </>
              }
              checked={includeSubtasks}
              onChange={e => setIncludeSubtasks(e.target.checked)}
            />

        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Выполнить'}
        </Button>
        <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
      </Form>
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}
    </Container>
  );
}

export default AddCommentToOverdueTasks;
