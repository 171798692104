import React, { useState } from 'react';
import { Button, Container, Form, Alert, FormCheck, Collapse} from 'react-bootstrap';
import { format, parseISO, addDays, addWeeks, isSaturday, isSunday } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import TooltipButton from '../components/TooltipButton';


const formatDateWithDay = (date) => {
    return format(date, 'dd.MM.yyyy (iiii)', { locale: ru });
};

const adjustDate = (date, moveToNextWeek) => {
    while (isSaturday(date) || isSunday(date)) {
        date = moveToNextWeek ? addDays(date, isSunday(date) ? 1 : 2) : addDays(date, -1);
    }
    return date;
};

const calculateDates = (release1PercentDate,
    moveToNextWeek, weeksUntilRelease, daysBeforeSubmit,
    daysAfterRelease, softUpdateOffset, forceUpdateOffset,
    codeFreezeOffset, ffOffset, ofbOffset, sbOffset,
    xx5sbOffset, xx5SubmitOffset, xx5CFOffset, xx5FFOffset, xx5release1offset, xx5release100offset) => {
    const nextRelease1Percent = addWeeks(release1PercentDate, weeksUntilRelease); // Следующий релиз на 1%
    const submit = adjustDate(addDays(nextRelease1Percent, (daysBeforeSubmit) * -1), moveToNextWeek); // Сабмит на понедельник перед релизом на 1%

    // Остальные даты для основной версии
    const release100Percent = adjustDate(addDays(nextRelease1Percent, daysAfterRelease), moveToNextWeek);
    const softUpdate = adjustDate(addDays(release100Percent, softUpdateOffset), moveToNextWeek);
    const forceUpdate = adjustDate(addDays(softUpdate, forceUpdateOffset), moveToNextWeek);
    const codeFreeze = adjustDate(addDays(submit, (codeFreezeOffset) * (-1)), moveToNextWeek);
    const featureFreeze = adjustDate(addDays(submit, (ffOffset) * (-1)), moveToNextWeek);
    const OFB = adjustDate(addDays(featureFreeze, (ofbOffset) * (-1)), moveToNextWeek);
    const separateBranch = adjustDate(addDays(OFB, (sbOffset) * (-1)), moveToNextWeek);

    // Даты для промежуточной версии
    const separateBranchXX5 = adjustDate(addWeeks(separateBranch, xx5sbOffset), moveToNextWeek);
    const submitXX5 = adjustDate(addWeeks(submit, xx5SubmitOffset), moveToNextWeek);
    const codeFreezeXX5 = adjustDate(addDays(submitXX5, (xx5CFOffset) * (-1)), moveToNextWeek);
    const featureFreezeXX5 = adjustDate(addDays(submitXX5, (xx5FFOffset) * (-1)), moveToNextWeek);
    const release1PercentXX5 = adjustDate(addDays(submitXX5, xx5release1offset), moveToNextWeek);
    const release100PercentXX5 = adjustDate(addDays(release1PercentXX5, xx5release100offset), moveToNextWeek);

    return {
        main: [
            { event: 'Отдельная ветка', date: formatDateWithDay(separateBranch) },
            { event: 'ОФБ', date: formatDateWithDay(OFB) },
            { event: 'Feature Freeze', date: formatDateWithDay(featureFreeze) },
            { event: 'Code Freeze', date: formatDateWithDay(codeFreeze) },
            { event: 'Сабмит', date: formatDateWithDay(submit) },
            { event: 'Релиз на 1%', date: formatDateWithDay(nextRelease1Percent) },
            { event: 'Релиз на 100%', date: formatDateWithDay(release100Percent) },
            { event: 'Soft Update', date: formatDateWithDay(softUpdate) },
            { event: 'Force Update', date: formatDateWithDay(forceUpdate) },
        ],
        intermediate: [
            { event: 'Отдельная ветка', date: formatDateWithDay(separateBranchXX5) },
            { event: 'Feature Freeze', date: formatDateWithDay(featureFreezeXX5) },
            { event: 'Code Freeze', date: formatDateWithDay(codeFreezeXX5) },
            { event: 'Сабмит', date: formatDateWithDay(submitXX5) },
            { event: 'Релиз на 1%', date: formatDateWithDay(release1PercentXX5) },
            { event: 'Релиз на 100%', date: formatDateWithDay(release100PercentXX5) },
        ],
    };
};

function ReleaseDatesCalculator() {

    const [release1PercentDateInput, setRelease1PercentDateInput] = useState('');
    const [dates, setDates] = useState(null);
    const [error, setError] = useState('');
    const [moveToNextWeek, setMoveToNextWeek] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const navigate = useNavigate();

    const [weeksUntilRelease, setWeeksUntilRelease] = useState(6); // Дефолтное значение для релиза на 1%
    const [daysBeforeSubmit, setDaysBeforeSubmit] = useState(2); // Дефолтное значение для сабмита
    const [daysAfterRelease, setDaysAfterRelease] = useState(5); // Дефолтное значение для релиза на 100%
    const [softUpdateOffset, setSoftUpdateOffset] = useState(2); // Дефолтное значение для софтапдейта
    const [forceUpdateOffset, setForceUpdateOffset] = useState(5); // Дефолтное значение для форсапдейта
    const [codeFreezeOffset, setCodeFreezeOffset] = useState(7); // Дефолтное значение для кодфриза
    const [ffOffset, setFFOffset] = useState(12); // Дефолтное значение для фичефриза
    const [ofbOffset, setOFBOffset] = useState(7); //Дефолтное значения для ОФБ
    const [sbOffset, setSBOffset] = useState(7); //Дефолтное значения для создания ветки

    const [xx5sbOffset, setxx5sbOffset] = useState(4); //Дефолтное значения для cоздания ветки хх5
    const [xx5SubmitOffset, setxx5SubmitOffset] = useState(3); //Дефолтное значения для сабмита хх5
    const [xx5CFOffset, setxx5CFOffset] = useState(7); //Дефолтное значения для CF хх5
    const [xx5FFOffset, setxx5FFOffset] = useState(10); //Дефолтное значения для CF хх5
    const [xx5release1offset, setxx5release1Offset] = useState(1); //Дефолтное значения для 1% хх5
    const [xx5release100offset, setxx5release100Offset] = useState(2); //Дефолтное значения для 1% хх5

    const handleCalculate = () => {
        if (!release1PercentDateInput) {
            setError('Пожалуйста, введите дату релиза на 1% в формате ГГГГ-ММ-ДД.');
            return;
        }
        setError('');
        const release1PercentDate = parseISO(release1PercentDateInput);
        const calculatedDates = calculateDates(release1PercentDate, moveToNextWeek, weeksUntilRelease,
            daysBeforeSubmit, daysAfterRelease, softUpdateOffset, forceUpdateOffset, codeFreezeOffset, ffOffset, ofbOffset, sbOffset,
            xx5sbOffset, xx5SubmitOffset, xx5CFOffset, xx5FFOffset, xx5release1offset, xx5release100offset);
        setDates(calculatedDates);
    };
      
    return (
        <Container className="mt-5">
            <h2>Калькулятор дат релизов</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>
                        Дата релиза на 1%
                        <TooltipButton tooltipText="Дата первого процента релиза для текущей или предыдущей версии. От этой даты будут рассчитаны все последующие даты." style={{ marginLeft: '5px' }} />
                    </Form.Label>
                    <Form.Control
                        type="date"
                        value={release1PercentDateInput}
                        onChange={(e) => setRelease1PercentDateInput(e.target.value)}
                    />
                </Form.Group>
                <Button
                    onClick={() => setShowSettings(!showSettings)}
                    aria-controls="settings-collapse"
                    aria-expanded={showSettings}
                >
                    Настройки
                </Button>
                <Collapse in={showSettings}>
                    <div id="settings-collapse" className="mt3">
                        <div className="row">
                            <div className="col">
                                <h3 className="mt-4">Настройки основной версии:</h3>
                                <Form.Group>
                                    <Form.Label>Отдельная ветка за (дней) до ОФБ</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={sbOffset}
                                        onChange={(e) => setSBOffset(e.target.value)}
                                    />
                                    <Form.Label>ОФБ за (дней) до FeatureFreeze</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={ofbOffset}
                                        onChange={(e) => setOFBOffset(e.target.value)}
                                    />
                                    <Form.Label>FeatureFreeze за (дней) до сабмита</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={ffOffset}
                                        onChange={(e) => setFFOffset(e.target.value)}
                                    />
                                    <Form.Label>CodeFreeze за (дней) до сабмита</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={codeFreezeOffset}
                                        onChange={(e) => setCodeFreezeOffset(e.target.value)}
                                    />
                                    <Form.Label>Сабмит за (дней) до релиза на 1%</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={daysBeforeSubmit}
                                        onChange={(e) => setDaysBeforeSubmit(e.target.value)}
                                    />
                                    <Form.Label>Релиз на 1% через (недель)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={weeksUntilRelease}
                                        onChange={(e) => setWeeksUntilRelease(e.target.value)}
                                    />

                                    <Form.Label>Релиз на 100% через (дней) после Релиза на 1%</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={daysAfterRelease}
                                        onChange={(e) => setDaysAfterRelease(e.target.value)}
                                    />
                                    <Form.Label>Softupdate через (дней) после Релиза на 100%</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={softUpdateOffset}
                                        onChange={(e) => setSoftUpdateOffset(e.target.value)}
                                    />
                                    <Form.Label>Forceupdate через (дней) после софтапдейта</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={forceUpdateOffset}
                                        onChange={(e) => setForceUpdateOffset(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col">
                                <h3 className="mt-4">Настройки промежуточной версии:</h3>
                                <Form.Group>
                                    <Form.Label>Отдельная ветка через (недель) после создания основной ветки</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={xx5sbOffset}
                                        onChange={(e) => setxx5sbOffset(e.target.value)}
                                    />
                                    <Form.Label>FeatureFreeze за (дней) до сабмита</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={xx5FFOffset}
                                        onChange={(e) => setxx5FFOffset(e.target.value)}
                                    />
                                    <Form.Label>CodeFreeze за (дней) до сабмита</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={xx5CFOffset}
                                        onChange={(e) => setxx5CFOffset(e.target.value)}
                                    />
                                    <Form.Label>Сабмит через (недель) после сабмита основной версии</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={xx5SubmitOffset}
                                        onChange={(e) => setxx5SubmitOffset(e.target.value)}
                                    />

                                    <Form.Label>Релиз на 1% через (дней) после сабмита</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={xx5release1offset}
                                        onChange={(e) => setxx5release1Offset(e.target.value)}
                                    />
                                    <Form.Label>Релиз на 100% через (дней) после сабмита</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={xx5release100offset}
                                        onChange={(e) => setxx5release100Offset(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group className="mb-3">
                            <FormCheck
                                type="checkbox"
                                label="Переносить на следующую неделю, если выпадает на выходные? (Если галочка не стоит - выбирает Пятницу)"
                                checked={moveToNextWeek}
                                onChange={(e) => setMoveToNextWeek(e.target.checked)}
                            />
                        </Form.Group>
                    </div>

                </Collapse>
                <br></br>
                <br></br>
                <Button onClick={handleCalculate}>Рассчитать</Button>
                <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
            </Form>
            {
                dates && (
                    <>
                        <h3 className="mt-4">Даты для основной версии:</h3>
                        <ul>
                            {dates.main.map(({ event, date }) => (
                                <li key={event}>{`${event}: ${date}`}</li>
                            ))}
                        </ul>
                        <h3>Даты для промежуточной версии:</h3>
                        <ul>
                            {dates.intermediate.map(({ event, date }) => (
                                <li key={event}>{`${event}: ${date}`}</li>
                            ))}
                        </ul>
                    </>
                )
            }
        </Container >
    );
}

export default ReleaseDatesCalculator;
