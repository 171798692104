import React, { useState} from 'react';
import { Button, Container, Form, Alert, Spinner} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';

function CreateSubtasks() {
  const [taskUrlOrId, setTaskUrlOrId] = useState('');
  const [subtaskCount, setSubtaskCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { log } = useFirebaseLogger();
  const asanaToken = localStorage.getItem('asanaToken');
  const [duplicateOptions, setDuplicateOptions] = useState({
    includeDescription: false,
    includeAttachments: false,
    includeDueDate: false,
    includeFollowers: false,
  });
  requireAuth(asanaToken);

  const handleOptionChange = (event) => {
    const { name, checked } = event.target;
    setDuplicateOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    log(localStorage.getItem('asanaUserName'), "Used CreateSubtasks Script");

    const taskIdMatch = taskUrlOrId.match(/\/(\d+)(?:\/f)?$/);
    const taskId = taskIdMatch ? taskIdMatch[1] : null;

    if (!taskId) {
      setMessage('Убедись, что ссылка или id задачи указан верно');
      setLoading(false);
      return;
    }

    try {

      const taskInfoResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      const taskName = taskInfoResponse.data.data.name;


      for (let i = 0; i < subtaskCount; i++) {
        // Duplicate task
        const duplicateResponse = await axios.post(`https://app.asana.com/api/1.0/tasks/${taskId}/duplicate`, {
          data: {
            name: taskName, // Используем название исходной задачи для дубликата
            include: [
              duplicateOptions.includeDescription ? 'notes' : null,
              duplicateOptions.includeAttachments ? 'attachments' : null,
              duplicateOptions.includeDueDate ? 'dates' : null,
              duplicateOptions.includeFollowers ? 'followers' : null,
            ].filter(Boolean),
          },
        }, {
          headers: { 'Authorization': `Bearer ${asanaToken}` },
        });
        

        const duplicatedTaskId = duplicateResponse.data.data.new_task.gid; // Используйте 'gid' вместо 'id'

        // Update parent of the duplicated task to make it a subtask
        await axios.post(`https://app.asana.com/api/1.0/tasks/${duplicatedTaskId}/setParent`, {
          data: {
            parent: taskId,
          }
        }, {
          headers: { 'Authorization': `Bearer ${asanaToken}` },
        });
      }
      setMessage('Подзадачи успешно созданы');
    } catch (error) {
      console.error('Ошибка при создании подзадач:', error);
      log(localStorage.getItem('asanaUserName'), "ERROR when using CreateSubtasks Script");
      setMessage(`Error: ${error.response?.data?.errors?.[0]?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Создание подзадач</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка или ID копируемой задачи</Form.Label>
          <TooltipButton tooltipText="Введите ссылку или ID задачи в Asana, которую необходимо продублировать в подзадачи. Продублированные подзадачи будут носить имя корневой" style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={taskUrlOrId} onChange={e => setTaskUrlOrId(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Количество подзадач</Form.Label>
          <TooltipButton tooltipText="Введите количество, сколько подзадач необходимо создать" style={{ marginLeft: '5px' }} />
          <Form.Control type="number" value={subtaskCount} onChange={e =>
            setSubtaskCount(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Дублировать описание?"
            name="includeDescription"
            checked={duplicateOptions.includeDescription}
            onChange={handleOptionChange}
          />
          <Form.Check
            type="checkbox"
            label="Дублировать вложения?"
            name="includeAttachments"
            checked={duplicateOptions.includeAttachments}
            onChange={handleOptionChange}
          />
          <Form.Check
            type="checkbox"
            label="Дублировать Due Date?"
            name="includeDueDate"
            checked={duplicateOptions.includeDueDate}
            onChange={handleOptionChange}
          />
          <Form.Check
            type="checkbox"
            label="Дублировать фолловеров?"
            name="includeFollowers"
            checked={duplicateOptions.includeFollowers}
            onChange={handleOptionChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Выполнить'}
        </Button>
        <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
      </Form>
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}
    </Container>
  );
}

export default CreateSubtasks;
