import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './AuthPage';
import MainPage from './MainPage';
import MassiveRename from './scripts/MassiveRename';
import CreateTaskWithSubtasks from './scripts/CreateTaskWithSubtasks';
import AddCommentToOverdueTasks from './scripts/AddCommentToOverdueTasks';
import CreateSubtasks from './scripts/CreateSubtasks';
import DuplicateTask from './scripts/DuplicateTask';
import Cutoff from './scripts/Cutoff';
import MoveSubtasksToProjectSection from './scripts/MoveSubtasksToProjectSection'
import SubtaskMassiveRename from './scripts/SubtaskMassiveRename';
import ReleaseDateCalculator from './scripts/ReleaseDateCalculator';
import MoveSubtasksFromLinks from './scripts/MoveToSubtasksFromLinks';
import GetHyperlinks from './scripts/GetHyperlinks.js';
import Changelog from './Changelog.js';
import TaskInSection from './scripts/TaskInSection.js'
import './utilities/firebaseConfig';


function App() {
  return (
      <Router>
        <Routes>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="*" element={<Navigate replace to="/auth" />} />
          <Route path="/massive-rename" element={<MassiveRename />} />
          <Route path="/create-task" element={<CreateTaskWithSubtasks />} />
          <Route path="/kicker" element={<AddCommentToOverdueTasks />} />
          <Route path="/subtasks" element={<CreateSubtasks />} />
          <Route path="/duplicator" element={<DuplicateTask />} />
          <Route path="/cutoff" element={<Cutoff />} />
          <Route path="/movesubs" element={<MoveSubtasksToProjectSection />} />
          <Route path="/sub-massive-rename" element={<SubtaskMassiveRename />} />
          <Route path="/release-calculator" element={<ReleaseDateCalculator />} />
          <Route path="/subs-from-links" element={<MoveSubtasksFromLinks />} />
          <Route path="/gethyperlinks" element={<GetHyperlinks />} />
          <Route path="/changelog" element={<Changelog />} />
          <Route path="/task-in-section" element={<TaskInSection />} />
        </Routes>
      </Router>
  );
}

export default App;
