import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Navbar, Card, Row, Col, Nav } from 'react-bootstrap';
import Footer from './Footer';
import './App.css';
import { useFirebaseLogger } from './utilities/useFirebaseLogger';

const scripts = [
  { emoji: "🔄", name: "Массовое переименование (для проектов)", description: "Скрипт будет полезен, если необходимо быстро, в рамках одного проекта переименовать повторяющиеся элементы в названии и описании. Например: Название версии. Скрипт переименует все задачи и подзадачи в указанном проекте.", action: "Выполнить" },
  { emoji: "🔁", name: "Массовое переименование (для корневых)", description: "Скрипт аналогичен скрипту для проектов, но работает внутри корневых задач. Массово заменяет искомую строку во всех подзадачах (в т.ч в описании) вплоть до самой последней вложенности.", action: "Выполнить" },
  { emoji: "📝", name: "Создание задачи с подзадачами", description: "Скрипт будет полезен для быстрого распространения информации. Он создает корневую задачу в MyTasks и неограниченное количество подзадач с идентичным названием, описанием и DueDate.", action: "Выполнить" },
  { emoji: "👞", name: "Напиныватель", description: "Скрипт проходится по задачам с просроченным DueDate и оставляет ваш комментарий.", action: "Выполнить" },
  { emoji: "📦", name: "Дубликатор в сабтаски", description: "Скрипт берет название, описание, DueDate указанной задачи и создает дубликаты в сабтасках. Может быть полезен для распространения информации, когда задача уже готова.", action: "Выполнить" },
  { emoji: "🔂", name: "Дубликатор задач", description: "Скрипт с гибкой настройкой. Позволяет продублировать задачу N количество раз, ее подзадачи, DueDate, фолловеров, назначения, описание, кастомные поля, теги. Скрипт автоматически добавит задачу в тот же проект, где находилась изначальная.", action: "Выполнить" },
  { emoji: "☠️", name: "Поиск зависших задач", description: "Скрипт находит 'Зависшие' задачи в указанном проекте, в которых не было никакой активности за указанное количество времени.", action: "Выполнить" },
  { emoji: "➕", name: "Добавление сабтасок в проект", description: "Скрипт будет полезен если необходмо добавить подзадачи в проект, при этом не убирая их из корневой.", action: "Выполнить" },
  { emoji: "🔗", name: "Добавление сабтасок в задачу по ссылкам", description: "Скрипт проходится по описанию корневой задачи, собирает там все ссылки на задачи и добавляет их сабтасками к корневой.", action: "Выполнить" },
  { emoji: "⛓️", name: "Получение гиперссылок", description: "Скрипт позволяет получить массово гипперссылки на задачи для удобной отправки, например в Slack", action: "Выполнить" },
  { emoji: "📅", name: "Калькулятор релизов", description: "Автоматически просчитывает ключевые даты следующей основной и промежуточной версии на базе даты Релиза на 1% текущей.", action: "Выполнить" },
  { emoji: "🚧", name: "Сколько дней задачи в статус?", description: "Проходится по задачам в проекте и указывает, сколько дней задача пролежала в определенном статусе без движения. Актуально для Scrum-like проектов.", action: "Выполнить" },

];

const scriptPaths = [
  '/massive-rename',
  '/sub-massive-rename',
  '/create-task',
  '/kicker',
  '/subtasks',
  '/duplicator',
  '/cutoff',
  '/movesubs',
  '/subs-from-links',
  '/gethyperlinks',
  '/release-calculator',
  '/task-in-section'
];

function MainPage() {
  const feedbackUrl = "https://form.asana.com/?k=2A791dGmddCK7ovhuw4ldA&d=656176460444";
  const navigate = useNavigate();
  const asanaUserName = localStorage.getItem('asanaUserName');
  const { log } = useFirebaseLogger();

  useEffect(() => {
    if (!asanaUserName) {
      navigate('/auth');
    }
  }, [navigate, asanaUserName]);

  const goToChangelog = useCallback(() => {
    navigate('/changelog');
  }, [navigate]);

  const handleLogout = useCallback(() => {
    log(asanaUserName, "Logged Out");
    localStorage.removeItem('asanaToken');
    localStorage.removeItem('asanaUserName');
    navigate('/auth');
  }, [log, navigate, asanaUserName]);

  const executeScript = useCallback((scriptIndex) => {
    const path = scriptPaths[scriptIndex];
    if (path) {
      navigate(path);
    } else {
      alert('Запуск скрипта: ' + scriptIndex);
    }
  }, [navigate]);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand>Привет, {asanaUserName}!</Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link onClick={goToChangelog} style={{color: '#fff', marginRight: '15px'}}>Changelog</Nav.Link>
            <Button variant="danger" onClick={handleLogout}>Выход</Button>
          </Nav>
        </Container>
      </Navbar>
      <Container className="pt-5 pb-5">
        <Row xs={1} md={2} lg={3} className="g-4">
          {scripts.map((script, index) => (
            <Col key={script.name}>
              <Card className="h-100">
                <Card.Body>
                  <Card.Title className="fs-1">{script.emoji} {script.name}</Card.Title>
                  <Card.Text>
                    {script.description}
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="d-grid">
                  <Button variant="primary" size="lg" onClick={() => executeScript(index)}>
                    Выполнить →
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
        <div className="mt-3">
          <Button className='feedback-btn btn btn-primary' onClick={() => window.location.href = feedbackUrl}>Фидбек</Button>
        </div>
        <Footer />
      </Container>
    </>
  );
}

export default MainPage;