import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Row, Col, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Changelog() {
  const [changes, setChanges] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChanges = async () => {
      try {
        const response = await axios.get('https://asanatools.cloud/Changelog.json');
        setChanges(response.data);
      } catch (err) {
        setError('Не удалось загрузить список изменений.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchChanges();
  }, []);

  return (
    <Container className="my-5">
      <Row className="mb-4">
        <Col>
          <h2>Журнал изменений</h2>
          {loading && <Spinner animation="border" variant="primary" />} {/* Индикатор загрузки */}
          {error && <Alert variant="danger">{error}</Alert>}
        </Col>
      </Row>
      {changes.length > 0 && (
        <Row xs={1} md={2} lg={3} className="g-4">
          {changes.map((change, index) => (
            <Col key={index}>
              <Card>
                <Card.Header as="h5">{change.title}</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <ul>
                      {change.changes.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <Button variant="secondary" onClick={() => navigate('/main')} className="mt-4">
        Назад
      </Button>
    </Container>
  );
}

export default Changelog;
