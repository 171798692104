import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRequireAuth = (asanaToken) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!asanaToken) {
      navigate('/auth');
    }
  }, [navigate, asanaToken]);
};

export default useRequireAuth;
