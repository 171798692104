import React, { useState } from 'react';
import { Button, Container, Form, Alert, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';

function UpdateTaskStatus() {
  const [projectId, setProjectId] = useState('');
  const [statusFieldName, setStatusFieldName] = useState('HS: Status (PL)');
  const [daysInStatusFieldName, setDaysInStatusFieldName] = useState('Task in Sections in Days (PL)');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const asanaToken = localStorage.getItem('asanaToken');
  const { log } = useFirebaseLogger();

  requireAuth(asanaToken);

  const updateTaskStatus = async () => {
    setLoading(true);
    setMessage('');
    setProgress(0);
    log(localStorage.getItem('asanaUserName'), "Used UpdateTaskStatus Script");

    try {
      const tasksResponse = await axios.get(`https://app.asana.com/api/1.0/projects/${projectId}/tasks?opt_fields=name,custom_fields`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      const tasks = tasksResponse.data.data;
      let processedTasks = 0;

      for (const task of tasks) {
        let customFields = task.custom_fields || [];
        let statusFieldId = null;
        let daysInStatusFieldId = null;
        let taskStatus = '';

        for (const field of customFields) {
          if (field.name === statusFieldName) {
            statusFieldId = field.gid;
            taskStatus = field.display_value;
          }
          if (field.name === daysInStatusFieldName) {
            daysInStatusFieldId = field.gid;
          }
        }

        if (!statusFieldId || taskStatus === '✅ Done') {
          continue;
        }

        const storiesResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${task.gid}/stories?opt_fields=created_at,text,type`, {
          headers: { 'Authorization': `Bearer ${asanaToken}` },
        });
        const stories = storiesResponse.data.data;
        let lastStatusChange = null;

        for (const story of stories) {
          if (story.type === 'system' && story.text.includes(`changed ${statusFieldName}`)) {
            lastStatusChange = new Date(story.created_at);
          }
        }

        if (!lastStatusChange) {
          continue;
        }

        const daysInStatus = Math.floor((new Date() - lastStatusChange) / (1000 * 60 * 60 * 24));
        let daysInStatusValue = daysInStatus < 1 ? "<1" : String(daysInStatus);

        if (daysInStatusFieldId) {
          await axios.put(`https://app.asana.com/api/1.0/tasks/${task.gid}`, {
            data: {
              custom_fields: {
                [daysInStatusFieldId]: daysInStatusValue
              }
            }
          }, {
            headers: { 'Authorization': `Bearer ${asanaToken}` },
          });
        }
        processedTasks += 1;
        setProgress(Math.round((processedTasks / tasks.length) * 100));
      }

      setMessage('Задачи успешно обновлены.');
    } catch (error) {
      setMessage(`Ошибка при обновлении задач: ${error.toString()}`);
      log(localStorage.getItem('asanaUserName'), "ERROR When using UpdateTaskStatus Script");
    } finally {
      setLoading(false);
      setProgress(100);
    }
  };

  return (
    <Container className="my-5">
      <h2>Разметка задач по дням нахождения в статусе</h2>
      {message && <Alert variant="info">{message}</Alert>}
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>ID проекта</Form.Label>
          <TooltipButton tooltipText="Введите ID проекта в Asana." style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={projectId} onChange={e => setProjectId(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Название пользовательского поля "HS: Status (PL)"</Form.Label>
          <TooltipButton tooltipText="Введите название пользовательского поля, которое соответствует статусу задач." style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={statusFieldName} onChange={e => setStatusFieldName(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Название пользовательского поля "Сколько дней задача в статусе?"</Form.Label>
          <TooltipButton tooltipText="Введите название пользовательского поля, которое будет обновлено." style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={daysInStatusFieldName} onChange={e => setDaysInStatusFieldName(e.target.value)} />
        </Form.Group>
        {loading && <ProgressBar animated now={progress} label={`${progress}%`} />}
        <br /><br />
        <Button variant="primary" onClick={updateTaskStatus} disabled={loading}>
          Выполнить
        </Button>
        <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
      </Form>
    </Container>
  );
}

export default UpdateTaskStatus;
