import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';


const firebaseConfig = {
    apiKey: "AIzaSyC7Tfm2IMVsOLgAcSIJEqtVrq0A_mQuu2o",
    authDomain: "asanatools.firebaseapp.com",
    projectId: "asanatools",
    storageBucket: "asanatools.appspot.com",
    messagingSenderId: "389970819911",
    appId: "1:389970819911:web:f98b75cd16fd21bb85aa62",
    measurementId: "G-F5YFPZ3YC4",
    databaseURL: "https://asanatools-default-rtdb.europe-west1.firebasedatabase.app/"

};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };