import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => (
  <footer className="fixed-bottom text-center bg-light p-2">
    <Container>
      Proudly crafted by Yaroslav Khaliullin and ChatGPT4 ❤️
    </Container>
  </footer>
);

export default Footer;
