import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Alert} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';


function MoveSubtasksFromLinks() {
  const [rootTaskUrl, setRootTaskUrl] = useState('');
  const [skipIfAlreadySubtask, setSkipIfAlreadySubtask] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { log } = useFirebaseLogger();
  const asanaToken = localStorage.getItem('asanaToken');

  requireAuth(asanaToken);

  useEffect(() => {
    if (message) {
      setLoading(false);
    }
  }, [message]);

  const extractTaskIdsFromDescription = async (rootTaskId) => {
    try {
      const response = await axios.get(`https://app.asana.com/api/1.0/tasks/${rootTaskId}`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      return extractTaskIds(response.data.data.notes);
    } catch (error) {
      log(localStorage.getItem('asanaUserName'), "ERROR When using MoveToSubtasksFromLinks Script");
      console.error('Ошибка при извлечении описания задачи:', error);
      return [];
    }
  };

  const extractTaskIds = text => {
    const taskUrlPattern = /https:\/\/app.asana.com\/0\/\d+\/(\d+)/g;
    const ids = [];
    let match;
    while ((match = taskUrlPattern.exec(text)) !== null) {
      ids.push(match[1]);
    }
    return ids;
  };

  const checkIfSubtask = async (taskId) => {
    try {
      const response = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      return !!response.data.data.parent;
    } catch (error) {
      log(localStorage.getItem('asanaUserName'), "ERROR When using MoveToSubtasksFromLinks Script");
      console.error('Ошибка при проверке подзадачи:', error);
      return false;
    }
  };

  const addSubtask = async (rootTaskId, subtaskId) => {
    try {
      await axios.post(`https://app.asana.com/api/1.0/tasks/${subtaskId}/setParent`, {
        data: {
          parent: rootTaskId,
        }
      }, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      return true;
    } catch (error) {
      log(localStorage.getItem('asanaUserName'), "ERROR When using MoveToSubtasksFromLinks Script");
      console.error('Ошибка при добавлении подзадачи:', error);
      return false;
    }
  };

  const handleAddSubtasksFromDescription = async () => {
    setLoading(true);
    setMessage('');
    const rootTaskId = rootTaskUrl.match(/\/(\d+)(?:\/f)?$/)?.[1];
    const taskIds = await extractTaskIdsFromDescription(rootTaskId);

    for (const taskId of taskIds) {
      if (skipIfAlreadySubtask && await checkIfSubtask(taskId)) {
        continue; // Пропускаем задачу, если она уже является подзадачей
      }
      await addSubtask(rootTaskId, taskId);
    }
    log(localStorage.getItem('asanaUserName'), "Used MoveToSubtasksFromLinks Script");
    setMessage('Задачи успешно добавлены в качестве подзадач.');
    setLoading(false);
  };

  return (
    <Container className="my-5">
      <h2>Добавление задач в качестве подзадач из описания</h2>
      {message && <Alert variant="info">{message}</Alert>}
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка на корневую задачу</Form.Label>
          <TooltipButton tooltipText="Вставьте ссылку на корневую задачу в Asana, в которой необходимо совершить поиск. В описании этой задачи должны быть ссылки на задачи, именно их скрипт и перенесет как сабтаски" style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={rootTaskUrl} onChange={e => setRootTaskUrl(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Group>
            <Form.Check
              type="checkbox"
              label={
                <>
                  Не переносить, если задача уже сабтаск к другой задаче
                  <TooltipButton tooltipText="Если галочка установлена, то скрипт проигнорирует задачи, которые уже являются сабтаском к какой-то другой задаче." style={{ marginLeft: '5px' }} />
                </>
              }
              checked={skipIfAlreadySubtask}
              onChange={e => setSkipIfAlreadySubtask(e.target.checked)}
            />
          </Form.Group>

        </Form.Group>

        <Button variant="primary" onClick={handleAddSubtasksFromDescription} disabled={loading}>
          Выполнить
        </Button>
        <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
      </Form>
    </Container>
  );
}

export default MoveSubtasksFromLinks;
