import React, { useState } from 'react';
import { Button, Container, Form, Alert, Spinner} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';



function MassiveRename() {
    const [projectId, setProjectId] = useState('');
    const [searchString, setSearchString] = useState('');
    const [replaceString, setReplaceString] = useState('');
    const [changeDescription, setChangeDescription] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { log } = useFirebaseLogger();
    const asanaToken = localStorage.getItem('asanaToken');
    const navigate = useNavigate();
    requireAuth(asanaToken)

    const handleRenameTasks = async () => {
        if (!projectId || !searchString || !replaceString) {
            setError('Все поля должны быть заполнены.');
            setLoading(false);
            return;
        }

        setLoading(true);
        setMessage('');
        setError('');
        log(localStorage.getItem('asanaUserName'), "Used Massive Rename Script");

        const extractedProjectId = projectId.match(/(\d+)$/)?.[0] || projectId;

        try {
            const tasksResponse = await axios.get(`https://app.asana.com/api/1.0/projects/${extractedProjectId}/tasks?opt_fields=name,notes,gid`, {
                headers: { 'Authorization': `Bearer ${asanaToken}` },
            });

            const tasks = tasksResponse.data.data;

            for (const task of tasks) {
                // Обновление главных задач
                let updateData = {};
                if (task.name.includes(searchString)) {
                    updateData.name = task.name.replace(searchString, replaceString);
                }
                if (changeDescription && task.notes && task.notes.includes(searchString)) {
                    updateData.notes = task.notes.replace(searchString, replaceString);
                }
                if (Object.keys(updateData).length > 0) {
                    await axios.put(`https://app.asana.com/api/1.0/tasks/${task.gid}`, {
                        data: updateData,
                    }, {
                        headers: { 'Authorization': `Bearer ${asanaToken}` },
                    });
                }

                // Получение и обновление подзадач
                const subtasksResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${task.gid}/subtasks?opt_fields=name,notes,gid`, {
                    headers: { 'Authorization': `Bearer ${asanaToken}` },
                });
                const subtasks = subtasksResponse.data.data;

                for (const subtask of subtasks) {
                    let subtaskUpdateData = {};
                    if (subtask.name.includes(searchString)) {
                        subtaskUpdateData.name = subtask.name.replace(searchString, replaceString);
                    }
                    if (changeDescription && subtask.notes && subtask.notes.includes(searchString)) {
                        subtaskUpdateData.notes = subtask.notes.replace(searchString, replaceString);
                    }
                    if (Object.keys(subtaskUpdateData).length > 0) {
                        await axios.put(`https://app.asana.com/api/1.0/tasks/${subtask.gid}`, {
                            data: subtaskUpdateData,
                        }, {
                            headers: { 'Authorization': `Bearer ${asanaToken}` },
                        });
                    }
                }
            }

            setMessage('Массовое переименование задач и подзадач выполнено успешно.');
        } catch (err) {
            console.error('Ошибка выполнения:', err);
            log(localStorage.getItem('asanaUserName'), "ERROR When using Massive Rename Script");
            setError(`Ошибка: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="mt-5">
            <h2>Массовое переименование задач в Asana</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Ссылка или ID проекта</Form.Label>
                    <TooltipButton tooltipText="Вставьте ссылку на проект в Asana, в котором необходимо произвести переименование." style={{ marginLeft: '5px' }} />
                    <Form.Control type="text" value={projectId} onChange={e => setProjectId(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Строка поиска</Form.Label>
                    <TooltipButton tooltipText="Введите текст, который нужно заменить в названии и/или описании задачи." style={{ marginLeft: '5px' }} />
                    <Form.Control type="text" value={searchString} onChange={e => setSearchString(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Строка замены</Form.Label>
                    <TooltipButton tooltipText="Введите текст, на который будет выполнена замена в названии и/или описании задачи." style={{ marginLeft: '5px' }} />
                    <Form.Control type="text" value={replaceString} onChange={e => setReplaceString(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label="Изменять в описании?"
                        checked={changeDescription}
                        onChange={e => setChangeDescription(e.target.checked)}
                    />
                </Form.Group>
                <Button onClick={handleRenameTasks} disabled={loading}>
                    {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Выполнить'}
                </Button>
                <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
            </Form>
            {message && <Alert variant="success" className="mt-3">{message}</Alert>}
        </Container>
    );
}

export default MassiveRename;
