import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Container, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';


const SubtaskMassiveRename = () => {
  const [rootTaskUrl, setRootTaskUrl] = useState('');
  const [searchString, setSearchString] = useState('');
  const [replaceString, setReplaceString] = useState('');
  const [changeInDescription, setChangeInDescription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { log } = useFirebaseLogger();
  const asanaToken = localStorage.getItem('asanaToken');
  requireAuth(asanaToken);

  const handleRename = async () => {
    setLoading(true);
    setMessage('');
    const taskId = rootTaskUrl.includes('/0/') ? rootTaskUrl.match(/\/(\d+)(?:\/f)?$/)?.[1] : rootTaskUrl;
    log(localStorage.getItem('asanaUserName'), "Used Script Subtask Massive Rename");

    try {
      await renameTaskAndSubtasks(taskId);
      setMessage('Переименование выполнено успешно.');
    } catch (error) {
      log(localStorage.getItem('asanaUserName'), "ERROR When using Subtask Massive Rename Script");
      setMessage(`Ошибка: ${error.response?.data?.errors?.[0]?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const renameTaskAndSubtasks = async (taskId, depth = 0) => {
    const taskResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}`, {
      headers: { 'Authorization': `Bearer ${asanaToken}` },
    });

    let { name, notes } = taskResponse.data.data;
    let updatedFields = {};

    if (name.includes(searchString)) {
      updatedFields.name = name.replace(searchString, replaceString);
    }
    if (changeInDescription && notes.includes(searchString)) {
      updatedFields.notes = notes.replace(searchString, replaceString);
    }
    if (Object.keys(updatedFields).length > 0) {
      await axios.put(`https://app.asana.com/api/1.0/tasks/${taskId}`, {
        data: updatedFields,
      }, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
    }

    // Получаем и обрабатываем подзадачи
    const subtasksResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}/subtasks`, {
      headers: { 'Authorization': `Bearer ${asanaToken}` },
    });

    for (const subtask of subtasksResponse.data.data) {
      await renameTaskAndSubtasks(subtask.gid, depth + 1);
    }
  };


  return (
    <Container className="mt-5">
      <h2>Массовое переименование в корневой задаче и подзадачах</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка на корневую задачу
            <TooltipButton tooltipText="Вставьте ссылку на корневую задачу в Asana, для которой нужно выполнить переименование." style={{ marginLeft: '5px' }} />
          </Form.Label>
          <Form.Control type="text" value={rootTaskUrl} onChange={e => setRootTaskUrl(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Строка поиска
            <TooltipButton tooltipText="Введите текст, который нужно заменить в названии и/или описании задачи." style={{ marginLeft: '5px' }} />
          </Form.Label>
          <Form.Control type="text" value={searchString} onChange={e => setSearchString(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Строка замены
            <TooltipButton tooltipText="Введите текст, на который будет выполнена замена в названии и/или описании задачи." style={{ marginLeft: '5px' }} />
          </Form.Label>
          <Form.Control type="text" value={replaceString} onChange={e => setReplaceString(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Менять в описании?"
            checked={changeInDescription}
            onChange={e => setChangeInDescription(e.target.checked)}
            id="changeInDescription"
          />
        </Form.Group>
        <Button variant="primary" onClick={handleRename} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Выполнить'}
        </Button>
        <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
      </Form>
      {message && <Alert variant="success">{message}</Alert>}
    </Container>
  );
};

export default SubtaskMassiveRename;
