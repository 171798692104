import React, { useState } from 'react';
import { Button, Form, Container} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';

const GetHyperlinks = () => {
  const [linksInput, setLinksInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [taskLinks, setTaskLinks] = useState([]);
  const navigate = useNavigate();
  const asanaToken = localStorage.getItem('asanaToken');
  const { log } = useFirebaseLogger();
  requireAuth(asanaToken);

  const handleGenerateLinks = async () => {

    setLoading(true);
    const linkLines = linksInput.split('\n').filter(line => line.trim() !== ''); // Уже удаляет пробельные строки
    const taskIds = linkLines.map(link =>
      link.trim().match(/\/(\d+)(?:\/f)?$/)?.[1] // Удаляем пробелы перед обработкой регулярным выражением
    ).filter(id => id);

    try {
      const tasksPromises = taskIds.map(taskId =>
        axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}`, {
          headers: { 'Authorization': `Bearer ${asanaToken}` },
        })
          .then(response => {
            const { name } = response.data.data;
            return { name, link: `https://app.asana.com/0/${taskId}/${taskId}` };
          })
          .catch(error => {
            console.error(`Ошибка при получении задачи ${taskId}`, error);
            return null; // Возвращаем null для задач, информацию по которым получить не удалось
          })
      );

      const resolvedTasks = await Promise.all(tasksPromises);
      setTaskLinks(resolvedTasks.filter(task => task !== null)); // Исключаем null значения
    } catch (error) {
      log(localStorage.getItem('asanaUserName'), "ERROR when used get HyperLinks");
      console.error('Ошибка при выполнении', error);
    } finally {
      log(localStorage.getItem('asanaUserName'), "Used GetHyperlinks Script");
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h2>Получение гипперссылок на задачи</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Вставьте ссылки на задачи</Form.Label>
          <TooltipButton tooltipText="Вставьте ссылки на задачи, разделять можно любым образом. Обычное массовое копирование из Asana тоже сработает." style={{ marginLeft: '5px' }} />
          <Form.Control as="textarea" rows={5} value={linksInput} onChange={e => setLinksInput(e.target.value)} />
        </Form.Group>
        <Button variant="primary" onClick={handleGenerateLinks} disabled={loading}>
          {loading ? 'Обработка...' : 'Выполнить'}
        </Button>
        <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
      </Form>
      {taskLinks.length > 0 && (
        <div className="mt-3">
          <h3>Ссылки на задачи:</h3>
          <div>
            {taskLinks.map((task, index) => (
              <div key={index}><a href={task.link} target="_blank" rel="noopener noreferrer">{task.name}</a></div>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default GetHyperlinks;
