import React, { useState } from 'react';
import { Button, Container, Form, Alert, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';

function MoveSubtasksToProjectSection() {
  const [taskUrlOrId, setTaskUrlOrId] = useState('');
  const [projectIdOrUrl, setProjectIdOrUrl] = useState('');
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [recursive, setRecursive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const asanaToken = localStorage.getItem('asanaToken');
  const { log } = useFirebaseLogger();

  requireAuth(asanaToken);

  const loadSections = async () => {
    setLoading(true);
    const projectId = projectIdOrUrl.match(/(\d+)$/)?.[0] || projectIdOrUrl;
    try {
      const response = await axios.get(`https://app.asana.com/api/1.0/projects/${projectId}/sections`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      setSections(response.data.data);
      setLoading(false);
    } catch (error) {
      setMessage(`Ошибка при загрузке секций: ${error.toString()}`);
      setLoading(false);
    }
  };

  const moveSubtasks = async () => {
    setLoading(true);
    setProgress(0);
    log(localStorage.getItem('asanaUserName'), "Used MoveSubtasksToProjectSection Script");
    setMessage('');
    const taskId = taskUrlOrId.includes('/0/') ? taskUrlOrId.match(/\/(\d+)(?:\/f)?$/)?.[1] : taskUrlOrId;

    const moveSubtasksRecursive = async (taskId, totalSubtasks = 1, processedSubtasks = 0) => {
      try {
        const subtasksResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}/subtasks`, {
          headers: { 'Authorization': `Bearer ${asanaToken}` },
          params: { opt_fields: 'gid' }
        });

        const subtasks = subtasksResponse.data.data;
        totalSubtasks += subtasks.length;

        for (const subtask of subtasks) {
          // Добавление подзадачи непосредственно в проект и указанную секцию
          await axios.post(`https://app.asana.com/api/1.0/tasks/${subtask.gid}/addProject`, {
            data: {
              project: projectIdOrUrl.match(/(\d+)$/)?.[0] || projectIdOrUrl,
              section: selectedSection
            }
          }, {
            headers: { 'Authorization': `Bearer ${asanaToken}` },
          });

          processedSubtasks += 1;
          setProgress(Math.round((processedSubtasks / totalSubtasks) * 100));

          // Рекурсивно обрабатываем подзадачи, если включен режим рекурсии
          if (recursive) {
            await moveSubtasksRecursive(subtask.gid, totalSubtasks, processedSubtasks);
          }
        }

        setMessage(`Все задачи успешно перенесены в секцию.`);
      } catch (error) {
        setMessage(`Ошибка при переносе задач: ${error.response?.data?.errors?.[0]?.message || error.message}`);
        log(localStorage.getItem('asanaUserName'), "ERROR When using MoveSubtasksToProjectSection Script");
      }
    };

    try {
      await moveSubtasksRecursive(taskId);
    } finally {
      setLoading(false);
      setProgress(100); // Завершить прогресс
    }
  };

  return (
    <Container className="my-5">
      <h2>Добавление подзадач в проект</h2>
      {message && <Alert variant="info">{message}</Alert>}
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка или ID корневой задачи</Form.Label>
          <TooltipButton tooltipText="Вставьте ссылку на корневую задачу в Asana, в которой находятся подзадачи для переноса." style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={taskUrlOrId} onChange={e => setTaskUrlOrId(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка или ID проекта</Form.Label>
          <TooltipButton tooltipText="Вставьте ссылку на проект в Asana, в который необходимо совершить перенос." style={{ marginLeft: '5px' }} />
          <Form.Control type="text" value={projectIdOrUrl} onChange={e => setProjectIdOrUrl(e.target.value)} />
        </Form.Group>
        <Button variant="secondary" onClick={loadSections} disabled={loading || !projectIdOrUrl}>
          Загрузить секции
        </Button>
        {sections.length > 0 && (
          <Form.Group className="mt-3">
            <Form.Label>Выберите секцию</Form.Label>
            <TooltipButton tooltipText="Выберите секцию в которую скрипт перенесет подзадачи" style={{ marginLeft: '5px' }} />
            <Form.Select onChange={e => setSelectedSection(e.target.value)}>
              <option value="">Выберите секцию</option>
              {sections.map(section => (
                <option key={section.gid} value={section.gid}>{section.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        <Form.Group className="mt-3">
          <Form.Check 
            type="checkbox" 
            label="Рекурсивно добавлять все подзадачи, включая все уровни вложенности?" 
            checked={recursive}
            onChange={e => setRecursive(e.target.checked)}
          />
        </Form.Group>
        {loading && <ProgressBar animated now={progress} label={`${progress}%`} />}
        <br></br>
        <br></br>
        <Button variant="primary" onClick={moveSubtasks} disabled={loading || !selectedSection}>
          Выполнить
        </Button>
        <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
      </Form>
    </Container>
  );
}

export default MoveSubtasksToProjectSection;
