import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipButton = ({ tooltipText, ...buttonProps }) => (
  <OverlayTrigger
    placement="right"
    overlay={(props) => (
      <Tooltip id={`tooltip-${props.id}`} {...props}>
        {tooltipText}
      </Tooltip>
    )}
  >
    <Button variant="outline-info" size="sm" {...buttonProps}>?</Button>
  </OverlayTrigger>
);

export default TooltipButton;
