import React, { useState } from 'react';
import { Button, Container, Form, Alert, Spinner, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFirebaseLogger } from '../utilities/useFirebaseLogger';
import TooltipButton from '../components/TooltipButton';
import requireAuth from '../utilities/requireAuth';


function DuplicateTask() {
    const [taskUrl, setTaskUrl] = useState('');
    const [copyCount, setCopyCount] = useState(1);
    const [copyDueDate, setCopyDueDate] = useState(false);
    const [copySubtasks, setCopySubtasks] = useState(false);
    const [copyCustomFields, setCopyCustomFields] = useState(false);
    const [copyTags, setCopyTags] = useState(false);
    const [copyFollowers, setCopyFollowers] = useState(false);
    const [copyAssignee, setCopyAssignee] = useState(false);
    const [copyAssigneeSub, setCopyAssigneeSub] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const asanaToken = localStorage.getItem('asanaToken');
    const navigate = useNavigate();
    const { log } = useFirebaseLogger();
    requireAuth(asanaToken);

    const handleDuplicateTask = async () => {
        if (!taskUrl || copyCount < 1) {
            setError('Пожалуйста, заполните все поля.');
            setLoading(false);
            return;
        }

        setLoading(true);
        setMessage('');
        setError('');
        log(localStorage.getItem('asanaUserName'), "Used DuplicateTask Script");

        const taskId = taskUrl.includes('/0/') ? taskUrl.match(/\/(\d+)(?:\/f)?$/)?.[1] : taskUrl;

        if (!taskId) {
            setError("Неправильный ID или URL задачи!.");
            setLoading(false);
            return;
        }

        try {
            const taskResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}`, {
                headers: { 'Authorization': `Bearer ${asanaToken}` },
                params: { opt_fields: 'name,notes,due_on,projects,custom_fields,tags,followers,workspace,assignee' }
            });

            const { name, notes, due_on, projects, custom_fields, tags, followers, workspace, assignee } = taskResponse.data.data;

            for (let i = 0; i < copyCount; i++) {
                let newTaskData = {
                    name,
                    notes,
                    assignee: copyAssignee && assignee ? assignee.gid : undefined,
                    workspace: workspace.gid,
                    followers: copyFollowers ? followers.map(follower => follower.gid) : undefined,
                };

                if (projects.length > 0) {
                    newTaskData.projects = projects.map(project => project.gid);
                }
                if (copyDueDate) newTaskData.due_on = due_on;
                if (copyCustomFields) {
                    newTaskData.custom_fields = custom_fields.reduce((acc, cf) => ({ ...acc, [cf.gid]: cf.enum_value ? cf.enum_value.gid : cf.text_value || cf.number_value }), {});
                }
                if (copyTags) {
                    newTaskData.tags = tags.map(tag => tag.gid);
                }

                const newTaskResponse = await axios.post(`https://app.asana.com/api/1.0/tasks`, {
                    data: newTaskData
                }, {
                    headers: { 'Authorization': `Bearer ${asanaToken}` },
                });

                const newTaskId = newTaskResponse.data.data.gid;

                if (copySubtasks) {
                    const subtasksResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}/subtasks?opt_fields=name,notes,due_on,custom_fields,tags,followers,assignee`, {
                        headers: { 'Authorization': `Bearer ${asanaToken}` },
                    });

                    for (const subtask of subtasksResponse.data.data) {
                        let newSubtaskData = {
                            name: subtask.name,
                            notes: subtask.notes,
                            assignee: copyAssigneeSub && subtask.assignee ? subtask.assignee.gid : undefined,
                        };

                        if (copyDueDate) newSubtaskData.due_on = subtask.due_on;
                        if (copyCustomFields && subtask.custom_fields) {
                            newSubtaskData.custom_fields = subtask.custom_fields.reduce((acc, cf) => ({ ...acc, [cf.gid]: cf.enum_value ? cf.enum_value.gid : cf.text_value || cf.number_value }), {});
                        }
                        if (copyTags && subtask.tags) {
                            newSubtaskData.tags = subtask.tags.map(tag => tag.gid);
                        }
                        if (copyFollowers && subtask.followers) {
                            newSubtaskData.followers = subtask.followers.map(follower => follower.gid);
                        }

                        await axios.post(`https://app.asana.com/api/1.0/tasks/${newTaskId}/subtasks`, {
                            data: newSubtaskData
                        }, {
                            headers: { 'Authorization': `Bearer ${asanaToken}` },
                        });
                    }
                }
            }

            setMessage('Задачи успешно скопированы.');
        } catch (err) {
            console.error('Ошибка:', err);
            log(localStorage.getItem('asanaUserName'), "ERROR When using DuplicateTask Script");
            setError(`Error: ${err.response?.data?.errors?.[0]?.message || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="mt-5">
            <h2>Дубликатор задач</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Ссылка на задачу или ID
                        <TooltipButton tooltipText="Введите URL задачи в Asana которую необходимо расскопировать." style={{ marginLeft: '5px' }} />
                    </Form.Label>

                    <Col sm={10}>
                        <Form.Control type="text" placeholder="" value={taskUrl} onChange={(e) => setTaskUrl(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Количество копий
                        <TooltipButton tooltipText="Укажите, сколько копий задачи необходимо создать." style={{ marginLeft: '5px' }} />
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="number" min="1" value={copyCount} onChange={(e) => setCopyCount(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Form.Check label="Копировать подзадачи?" checked={copySubtasks} onChange={(e) => setCopySubtasks(e.target.checked)} />
                        <Form.Check label="Копировать назначение корневой? - ВАЖНО! Обязательно, если задача находится в MyTasks" checked={copyAssignee} onChange={(e) => setCopyAssignee(e.target.checked)} />
                        <Form.Check label="Копировать назначение подзадач?" checked={copyAssigneeSub} onChange={(e) => setCopyAssigneeSub(e.target.checked)} />
                        <Form.Check label="Копировать DueDate?" checked={copyDueDate} onChange={(e) => setCopyDueDate(e.target.checked)} />
                        <Form.Check label="Копировать кастомные поля?" checked={copyCustomFields} onChange={(e) => setCopyCustomFields(e.target.checked)} />
                        <Form.Check label="Копировать теги?" checked={copyTags} onChange={(e) => setCopyTags(e.target.checked)} />
                        <Form.Check label="Копировать фолловеров?" checked={copyFollowers} onChange={(e) => setCopyFollowers(e.target.checked)} />
                    </Col>
                </Form.Group>
                <Button variant="primary" onClick={handleDuplicateTask} disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Выполнить'}
                </Button>
                <Button variant="secondary" onClick={() => navigate('/main')} className="ms-2">Назад</Button>
            </Form>
            {message && <Alert variant="success" className="mt-3">{message}</Alert>}
        </Container>
    );
}

export default DuplicateTask;
