import { ref, push } from 'firebase/database';
import { database } from './firebaseConfig';

export const useFirebaseLogger = () => {
  const log = (userName, message) => {
    // Создаем безопасный ключ для имени пользователя, заменяя пробелы на подчеркивания
    const userKey = userName.replace(/\s+/g, '_');

    // Используем имя пользователя в качестве части пути в Firebase
    const logRef = ref(database, `logs/${userKey}/actions`);

    // Добавляем запись лога под этим путем
    push(logRef, {
      message,
      timestamp: new Date().toISOString(),
    });
  };

  return { log };
};
